var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"wine-v1.38.3"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/wine";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"

// see here for the full list https://docs.sentry.io/platforms/javascript/configuration/integrations/#available-integrations
const disabledDefaultExtensions = [
  // disable replay to prepare for switch to on-cloud instance, team plan only includes 50 replays :(
  "Replay",
  "ReplayCanvas",
]

Sentry.init({
  debug: false,

  dsn: "https://d9704b28e17128ec2d515a5440041b7a@o216259.ingest.us.sentry.io/4507940377985025",

  // disable replay to prepare for switch to on-cloud instance
  // the team plan provides "10M span units", but let's check what happens fully disabling the tracing feature
  enableTracing: true,
  environment: process.env.NEXT_PUBLIC_APP_ENV,

  ignoreErrors: [
    // https://stackoverflow.com/questions/72720387/cant-find-variable-autofillcallbackhandler
    "ReferenceError: Can't find variable: _AutofillCallbackHandler",
  ],

  integrations: function (integrations) {
    return integrations.filter(function (integration) {
      return !disabledDefaultExtensions.includes(integration.name)
    })
  },

  tracesSampleRate: 0.1,
})
